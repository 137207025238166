import {
  IBenchMarkDropdownResponseDTO,
  ICreateRenewableTargetDTO,
  IRenewableTargetBenchMarkRequestDTO,
  IRenewableTargetBenchMarkResponseDTO,
  ITargetRenewableTargetDTO,
  IVerifyFacilityDTO,
} from "@/models/RenewableEnergyTarget";
import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { RenewableEnergyTargetAPI } from "@/services/renewableEnergyTarget";

const name = "RenewableEnergyTargetModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class RenewableEnergyTargetModule extends VuexModule {
  private errorGetTrackingMethods = {} as ErrorResponse;
  private errorGetStandards = {} as ErrorResponse;
  private errorGetRenewableTarget = {} as ErrorResponse;
  private errorGetRenewableTargetDetail = {} as ErrorResponse;
  private errorUpdateRenewableTargetDetail = {} as ErrorResponse;
  private errorTargetGetRenewableTarget = {} as ErrorResponse;
  private dataTrackingMethods = [] as any;
  private dataStandards = [] as any;
  private dataListRenewableTarget = [] as any;
  private dataRenewableTargetDetail = {} as any;
  private isLoadingListRenewableTarget = [] as any;
  private isLoadingTrackingMethods = false;
  private isLoadingCreateRenewableTarget = false;
  private isLoadingGetRenewableTargetDetail = false;
  private dataRenewableTargetBenchMark =
    [] as IRenewableTargetBenchMarkResponseDTO[];

  private dataBenchMarkDropDown = [] as IBenchMarkDropdownResponseDTO[];
  private errorBenchMarkDropDown = {} as ErrorResponse;
  private dataRenewableTargetBenchMarkPagination = {} as any;
  private isLoadingRenewableTargetBenchMark = false as boolean;
  private dataTargetGetRenewableTarget = {} as ITargetRenewableTargetDTO;
  private isLoadingTargetGetRenewableTarget = false as boolean;
  get listDataTrackingMethodsGetter() {
    return this.dataTrackingMethods;
  }

  get listDataStandardsGetter() {
    return this.dataStandards;
  }
  get isLoadingCreateRenewableTargetGetter() {
    return this.isLoadingCreateRenewableTarget;
  }

  get listRenewableTargetGetter() {
    return this.dataListRenewableTarget;
  }
  get isLoadingListRenewableTargetGetter() {
    return this.isLoadingListRenewableTarget;
  }

  get dataRenewableTargetDetailGetter() {
    return this.dataRenewableTargetDetail;
  }
  get isLoadingGetRenewableTargetDetailGetter() {
    return this.isLoadingGetRenewableTargetDetail;
  }

  @Mutation
  setLoadingTrackingMethods(loading: boolean) {
    this.isLoadingTrackingMethods = loading;
  }
  @Mutation
  getTrackingMethodFailure(error: ErrorResponse) {
    this.errorGetTrackingMethods = error.message;
    this.isLoadingTrackingMethods = false;
  }
  @Mutation
  getTrackingMethodSuccess(data: any) {
    this.errorGetTrackingMethods = {} as ErrorResponse;
    this.dataTrackingMethods = data;
    this.isLoadingTrackingMethods = false;
  }
  @Action({ rawError: true })
  async getTrackingMethod() {
    this.setLoadingTrackingMethods(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error, data } =
      await servicesRenewableTargetAPI.getTrackingMethod();

    if (error) {
      this.getTrackingMethodFailure(error);
    } else {
      this.getTrackingMethodSuccess(data);
    }
  }

  @Mutation
  getStandardsFailure(error: ErrorResponse) {
    this.errorGetStandards = error.message;
  }
  @Mutation
  getStandardsSuccess(data: any) {
    this.errorGetStandards = {} as ErrorResponse;
    this.dataStandards = data;
  }
  @Action({ rawError: true })
  async getStandards() {
    this.setLoadingTrackingMethods(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error, data } = await servicesRenewableTargetAPI.getStandards();

    if (error) {
      this.getStandardsFailure(error);
    } else {
      this.getStandardsSuccess(data);
    }
  }

  @Mutation
  setLoadingCreateRenewableTarget(loading: boolean) {
    this.isLoadingCreateRenewableTarget = loading;
  }
  @Mutation
  createRenewableTargetFailure(error: ErrorResponse) {
    this.errorGetTrackingMethods = error.message;
    ElMessage.error("Create Renewable Target fail!");
    this.isLoadingCreateRenewableTarget = false;
  }
  @Mutation
  createRenewableTargetSuccess() {
    this.errorGetTrackingMethods = {} as ErrorResponse;
    ElMessage.success("Successful Create Renewable Target!");
    this.isLoadingCreateRenewableTarget = false;
  }
  @Action({ rawError: true })
  async createRenewableTarget(payload: ICreateRenewableTargetDTO) {
    this.setLoadingCreateRenewableTarget(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error } = await servicesRenewableTargetAPI.createRenewableTarget(
      payload
    );

    if (error) {
      this.createRenewableTargetFailure(error);
      return error;
    } else {
      this.createRenewableTargetSuccess();
    }
  }

  get isLoadingRenewableTargetBenchMarkGetter() {
    return this.isLoadingRenewableTargetBenchMark;
  }

  get dataRenewableTargetBenchMarkGetter() {
    return this.dataRenewableTargetBenchMark;
  }

  get dataRenewableTargetBenchMarkPaginationGetter() {
    return this.dataRenewableTargetBenchMarkPagination;
  }

  @Mutation
  setLoadingRenewableTargetBenchMark(loading: boolean) {
    this.isLoadingRenewableTargetBenchMark = loading;
  }
  @Mutation
  setRenewableTargetBenchMarkFailure(error: ErrorResponse) {
    this.errorGetTrackingMethods = error.message;
    this.isLoadingRenewableTargetBenchMark = false;
  }
  @Mutation
  setRenewableTargetBenchMarkSuccess(data: any) {
    this.errorGetTrackingMethods = {} as ErrorResponse;
    this.isLoadingRenewableTargetBenchMark = false;
    const { Collection, ...rest } = data;
    this.dataRenewableTargetBenchMark = Collection;
    this.dataRenewableTargetBenchMarkPagination = rest;
  }
  @Action({ rawError: true })
  async getRenewableTargetBenchMark(
    payload?: IRenewableTargetBenchMarkRequestDTO
  ) {
    this.setLoadingCreateRenewableTarget(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { data, error } = await servicesRenewableTargetAPI.getBenchmark(
      payload
    );

    if (error) {
      this.setRenewableTargetBenchMarkFailure(error);
    } else {
      this.setRenewableTargetBenchMarkSuccess(data);
    }
  }

  get dataBenchMarkDropDownGetter() {
    return this.dataBenchMarkDropDown;
  }
  @Mutation
  setBenchMarkDropDownFailure(error: ErrorResponse) {
    this.errorBenchMarkDropDown = error;
  }
  @Mutation
  setBenchMarkDropDownSuccess(data: IBenchMarkDropdownResponseDTO[]) {
    this.dataBenchMarkDropDown = data;
  }
  @Action({ rawError: true })
  async getRenewableTargetBenchMarkDropDown() {
    this.setLoadingCreateRenewableTarget(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { data, error } =
      await servicesRenewableTargetAPI.getBenchmarkDropDown();

    if (error) {
      this.setBenchMarkDropDownFailure(error);
    } else {
      this.setBenchMarkDropDownSuccess(data);
    }
  }

  @Mutation
  setLoadingListRenewableTarget(loading: boolean) {
    this.isLoadingListRenewableTarget = loading;
  }
  @Mutation
  getRenewableTargetFailure(error: ErrorResponse) {
    this.errorGetRenewableTarget = error.message;
    this.isLoadingListRenewableTarget = false;
  }
  @Mutation
  getRenewableTargetSuccess(data: ICreateRenewableTargetDTO) {
    this.errorGetRenewableTarget = {} as ErrorResponse;
    this.dataListRenewableTarget = data;
    this.isLoadingListRenewableTarget = false;
  }
  @Action({ rawError: true })
  async getRenewableTarget() {
    this.setLoadingListRenewableTarget(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error, data } =
      await servicesRenewableTargetAPI.getRenewableTarget();

    if (error) {
      this.getRenewableTargetFailure(error);
    } else {
      this.getRenewableTargetSuccess(data);
    }
  }

  @Mutation
  setLoadingGetRenewableTargetDetail(loading: boolean) {
    this.isLoadingGetRenewableTargetDetail = loading;
  }
  @Mutation
  getRenewableTargetDetailFailure(error: ErrorResponse) {
    this.errorGetRenewableTargetDetail = error.message;
    this.isLoadingGetRenewableTargetDetail = false;
  }
  @Mutation
  getRenewableTargetDetailSuccess(data: ICreateRenewableTargetDTO) {
    this.dataRenewableTargetDetail = data;
    this.errorGetRenewableTargetDetail = {} as ErrorResponse;
    this.isLoadingGetRenewableTargetDetail = false;
  }
  @Action({ rawError: true })
  async getRenewableTargetDetail(id: string) {
    this.setLoadingGetRenewableTargetDetail(true);

    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error, data } =
      await servicesRenewableTargetAPI.getRenewableTargetDetail(id);

    if (error) {
      this.getRenewableTargetDetailFailure(error);
    } else {
      this.getRenewableTargetDetailSuccess(data);
    }
  }

  @Mutation
  updateRenewableTargetDetailFailure(error: ErrorResponse) {
    this.errorUpdateRenewableTargetDetail = error.message;
    ElMessage.error("Update Renewable Target fail!");
  }
  @Mutation
  updateRenewableTargetDetailSuccess() {
    this.errorUpdateRenewableTargetDetail = {} as ErrorResponse;
    ElMessage.success("Successful Update Renewable Target!");
  }
  @Action({ rawError: true })
  async updateRenewableTargetDetail({
    id,
    data,
  }: {
    id: string;
    data: ICreateRenewableTargetDTO;
  }) {
    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error } =
      await servicesRenewableTargetAPI.updateRenewableTargetDetail(id, data);

    if (error) {
      this.updateRenewableTargetDetailFailure(error);
    } else {
      this.updateRenewableTargetDetailSuccess();
    }
  }

  @Action({ rawError: true })
  async verifyFacilityActive(payload: IVerifyFacilityDTO) {
    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { data } = await servicesRenewableTargetAPI.verifyFacilityActive(
      payload
    );

    if (data) {
      return data;
    }
  }

  @Mutation
  setRenewableSetTargetDashboardFailure(error: ErrorResponse) {
    this.errorGetRenewableTarget = error.message;
    ElMessage.success("Set Renewable Target Dashboard Error!");
  }
  @Mutation
  setRenewableSetTargetDashboardSuccess() {
    this.errorGetRenewableTarget = {} as ErrorResponse;
    ElMessage.success("Set Renewable Target Dashboard Success!");
  }
  @Action({ rawError: true })
  async updateRenewableTarget(payload: { id: string }) {
    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error, data } =
      await servicesRenewableTargetAPI.setRenewableTargetDashboard(payload);
    if (error) {
      this.setRenewableSetTargetDashboardFailure(error);
    } else {
      this.setRenewableSetTargetDashboardSuccess();
    }
  }

  get dataTargetGetRenewableTargetGetter() {
    return this.dataTargetGetRenewableTarget;
  }
  get isLoadingTargetGetRenewableTargetGetter() {
    return this.isLoadingTargetGetRenewableTarget;
  }
  @Mutation
  setIsLoadingTargetRenewableTarget(isLoading: boolean) {
    this.isLoadingTargetGetRenewableTarget = isLoading;
  }
  @Mutation
  setTargetRenewableTargetFailure(error: ErrorResponse) {
    this.errorTargetGetRenewableTarget = error.message;
    this.isLoadingTargetGetRenewableTarget = false;
  }
  @Mutation
  setTargetRenewableTargetSuccess(data: ITargetRenewableTargetDTO) {
    this.errorTargetGetRenewableTarget = {} as ErrorResponse;
    this.dataTargetGetRenewableTarget = data;
    this.isLoadingTargetGetRenewableTarget = false;
  }
  @Action({ rawError: true })
  async getTargetRenewableTarget() {
    this.setIsLoadingTargetRenewableTarget(true);
    const servicesRenewableTargetAPI = new RenewableEnergyTargetAPI();
    const { error, data } =
      await servicesRenewableTargetAPI.getTargetRenewableTargetDashboard();
    if (error) {
      this.setTargetRenewableTargetFailure(error);
    } else {
      this.setTargetRenewableTargetSuccess(data);
    }
  }
}
export default getModule(RenewableEnergyTargetModule);
