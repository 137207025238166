import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import {
  ICreateRenewableTargetDTO,
  IRenewableTargetBenchMarkRequestDTO,
  IVerifyFacilityDTO,
} from "@/models/RenewableEnergyTarget";
import { IResponse } from "@/services/axios/entities";

const RENEWABLE_TARGET = "ScopeTwoRenewableTargets";
export class RenewableEnergyTargetAPI extends HttpClient {
  constructor(private env = JSON.parse(localStorage.getItem("env") as string)) {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public createRenewableTarget = async (
    data: ICreateRenewableTargetDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${RENEWABLE_TARGET}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getTrackingMethod = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}/trackingmethod`)
      .catch(catchAxiosError);

    return response;
  };

  public getStandards = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}/standards`)
      .catch(catchAxiosError);

    return response;
  };

  public getBenchmark = async (
    params?: IRenewableTargetBenchMarkRequestDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}/references`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getBenchmarkDropDown = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}/references/dropdown`)
      .catch(catchAxiosError);

    return response;
  };

  public getRenewableTarget = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}`)
      .catch(catchAxiosError);

    return response;
  };

  public getRenewableTargetDetail = async (id: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}/${id}`)
      .catch(catchAxiosError);

    return response;
  };

  public updateRenewableTargetDetail = async (
    id: string,
    data: ICreateRenewableTargetDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${RENEWABLE_TARGET}/${id}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public verifyFacilityActive = async (
    params: IVerifyFacilityDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${RENEWABLE_TARGET}/verify`, params)
      .catch(catchAxiosError);

    return response;
  };

  public setRenewableTargetDashboard = async (params: {
    id: string;
  }): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${RENEWABLE_TARGET}/${params.id}/renewableTarget`)
      .catch(catchAxiosError);

    return response;
  };
  public getTargetRenewableTargetDashboard = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLE_TARGET}/target`)
      .catch(catchAxiosError);

    return response;
  };
}
